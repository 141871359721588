@charset "UTF-8";
.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
  padding-right: 25px;
  max-width: 147rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 992px) {
    .pux-container {
      padding-left: 15.5rem;
      padding-right: 15.5rem; } }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: 0;
      margin-right: 0; } }

.pux-container [class^="col-"] {
  padding-left: 0;
  padding-right: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  @media (min-width: 768px) {
    .pux-container [class^="col-"] {
      padding-left: 0;
      padding-right: 0; } }

.pux-fluid-container {
  padding-left: 25px;
  padding-right: 25px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

header {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 77; }
  @media (min-width: 992px) {
    header {
      box-shadow: 0 0 38px rgba(0, 0, 0, 0.23); } }
  @media (min-width: 992px) {
    header.scrolled .header {
      padding: 2rem 0rem;
      height: 6rem; } }
  @media (min-width: 992px) {
    header.scrolled .header-logo {
      -webkit-transform: translateX(-10%) scale(0.8);
      -moz-transform: translateX(-10%) scale(0.8);
      -ms-transform: translateX(-10%) scale(0.8);
      -o-transform: translateX(-10%) scale(0.8);
      transform: translateX(-10%) scale(0.8); } }
  @media (max-width: 991px) {
    header .pux-container {
      padding: 0; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.18);
    padding: 1rem 25px;
    height: 9rem;
    position: relative;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      header .header {
        padding: 1rem 40px; } }
    @media (min-width: 992px) {
      header .header {
        padding: 1rem 0;
        box-shadow: none;
        z-index: 200; } }
    header .header-logo {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      header .header-logo a {
        display: inline-block; }
        header .header-logo a span {
          display: none; }
        header .header-logo a img {
          padding: 0;
          width: 15rem;
          height: 6.4rem !important; }
    header .header-box {
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      position: relative;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      header .header-box.open .header-searchbox {
        display: block; }
      header .header-box .lang-switch,
      header .header-box .header-search {
        padding-right: 20px; }
      header .header-box .lang-switch {
        order: 1; }
        @media (min-width: 992px) {
          header .header-box .lang-switch {
            order: 2;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center; } }
        @media (min-width: 992px) {
          header .header-box .lang-switch:before {
            content: "";
            height: 38px;
            width: 1px;
            background: #bbbbbb;
            margin-right: 20px;
            display: block; } }
      header .header-box .header-search {
        order: 2;
        height: 22px; }
        @media (min-width: 992px) {
          header .header-box .header-search {
            order: 1; } }
        @media (min-width: 1400px) {
          header .header-box .header-search {
            height: 25px; } }
        header .header-box .header-search .search-switch {
          height: 22px;
          font-size: 18px; }
          @media (min-width: 1400px) {
            header .header-box .header-search .search-switch {
              height: 25px; } }
          @media (min-width: 1400px) {
            header .header-box .header-search .search-switch {
              font-size: 20.5px; } }
      header .header-box .header-hamburger {
        order: 3; }
      header .header-box .header-searchbox {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        width: 280px;
        padding: 0 5px 0 15px;
        background: #fff;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        -ms-border-radius: 7px;
        border-radius: 7px;
        filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.07));
        -webkit-transform: translate(0, 10px);
        -moz-transform: translate(0, 10px);
        -ms-transform: translate(0, 10px);
        -o-transform: translate(0, 10px);
        transform: translate(0, 10px); }
        @media (min-width: 375px) {
          header .header-box .header-searchbox {
            width: 320px; } }
        @media (min-width: 545px) {
          header .header-box .header-searchbox {
            width: 420px; } }
        @media (min-width: 768px) {
          header .header-box .header-searchbox {
            -webkit-transform: translate(0, 18px);
            -moz-transform: translate(0, 18px);
            -ms-transform: translate(0, 18px);
            -o-transform: translate(0, 18px);
            transform: translate(0, 18px); } }
        @media (min-width: 992px) {
          header .header-box .header-searchbox {
            -webkit-transform: translate(-100%, 18px);
            -moz-transform: translate(-100%, 18px);
            -ms-transform: translate(-100%, 18px);
            -o-transform: translate(-100%, 18px);
            transform: translate(-100%, 18px);
            right: none;
            left: 90px; } }
        @media (min-width: 1400px) {
          header .header-box .header-searchbox {
            width: 460px;
            max-width: 460px; } }
        header .header-box .header-searchbox:before {
          content: "";
          display: block;
          position: absolute;
          top: 1px;
          right: 0;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #fff;
          -webkit-transform: translate(-40px, -100%);
          -moz-transform: translate(-40px, -100%);
          -ms-transform: translate(-40px, -100%);
          -o-transform: translate(-40px, -100%);
          transform: translate(-40px, -100%); }
          @media (min-width: 992px) {
            header .header-box .header-searchbox:before {
              -webkit-transform: translate(-70px, -100%);
              -moz-transform: translate(-70px, -100%);
              -ms-transform: translate(-70px, -100%);
              -o-transform: translate(-70px, -100%);
              transform: translate(-70px, -100%); } }
        header .header-box .header-searchbox:after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          left: 50%;
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
          background: #eb140a;
          height: 2px;
          width: 270px; }
          @media (min-width: 375px) {
            header .header-box .header-searchbox:after {
              width: 310px; } }
          @media (min-width: 545px) {
            header .header-box .header-searchbox:after {
              width: 410px; } }
          @media (min-width: 1400px) {
            header .header-box .header-searchbox:after {
              width: 450px; } }
        header .header-box .header-searchbox .searchBox {
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; }
          header .header-box .header-searchbox .searchBox .btn {
            padding: 0 30px 0 20px;
            height: 50px;
            background: #eb140a;
            color: #fff; }
          header .header-box .header-searchbox .searchBox input {
            margin-right: 15px; }
          header .header-box .header-searchbox .searchBox label {
            display: none; }
          header .header-box .header-searchbox .searchBox:after {
            position: absolute;
            bottom: 50%;
            right: 30px;
            content: "\e903";
            color: #fff;
            font-size: 16px;
            -webkit-transform: translateY(50%);
            -moz-transform: translateY(50%);
            -ms-transform: translateY(50%);
            -o-transform: translateY(50%);
            transform: translateY(50%); }
      header .header-box ul {
        margin: 0;
        list-style: none;
        padding: 0; }
    header .header-menu {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      -webkit-transform: translateX(1000px);
      -moz-transform: translateX(1000px);
      -ms-transform: translateX(1000px);
      -o-transform: translateX(1000px);
      transform: translateX(1000px);
      position: absolute;
      top: 100%;
      z-index: -1;
      right: 0;
      background: #fff;
      min-width: 280px;
      padding: 20px 0 55px 90px;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem);
      -webkit-border-radius: 0 0 0 7px;
      -moz-border-radius: 0 0 0 7px;
      -ms-border-radius: 0 0 0 7px;
      border-radius: 0 0 0 7px; }
      @media (min-width: 992px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: auto;
          min-width: none;
          -webkit-transform: translateX(0px);
          -moz-transform: translateX(0px);
          -ms-transform: translateX(0px);
          -o-transform: translateX(0px);
          transform: translateX(0px);
          padding: 0px; } }
      header .header-menu:after {
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        background: #eb140a;
        height: 2px;
        width: 276px; }
        @media (min-width: 992px) {
          header .header-menu:after {
            display: none; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 992px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; } }
        header .header-menu ul li {
          display: block;
          position: relative; }
          @media (min-width: 992px) {
            header .header-menu ul li {
              margin: 0 1rem;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          header .header-menu ul li a, header .header-menu ul li span {
            display: block;
            padding: 15px 0;
            color: #282828;
            font-size: 14px;
            text-transform: uppercase;
            color: #7f8080; }
            @media (min-width: 992px) {
              header .header-menu ul li a, header .header-menu ul li span {
                padding: 0 25px; } }
            @media (min-width: 1200px) {
              header .header-menu ul li a, header .header-menu ul li span {
                font-size: 15px;
                padding: 0 30px; } }
            header .header-menu ul li a:focus, header .header-menu ul li a:hover, header .header-menu ul li span:focus, header .header-menu ul li span:hover {
              color: #eb140a;
              text-decoration: none; }
          header .header-menu ul li.Highlighted a {
            color: #eb140a; }
          header .header-menu ul li ul {
            background: #fff;
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 992px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 180px;
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 40px;
                box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
                padding: 10px 0;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                -ms-border-radius: 7px;
                border-radius: 7px;
                filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.07)); } }
            @media (min-width: 992px) {
              header .header-menu ul li ul:before {
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 50%;
                -webkit-transform: translate(-50%, -100%);
                -moz-transform: translate(-50%, -100%);
                -ms-transform: translate(-50%, -100%);
                -o-transform: translate(-50%, -100%);
                transform: translate(-50%, -100%);
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff; } }
            @media (min-width: 992px) {
              header .header-menu ul li ul:after {
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                -webkit-transform: translate(-50%, 0);
                -moz-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                background: #eb140a;
                height: 2px;
                width: 168px; } }
            @media (min-width: 992px) {
              header .header-menu ul li ul li {
                padding: 0 15px 0 30px; } }
            header .header-menu ul li ul li a {
              text-transform: none;
              position: relative;
              font-weight: 300;
              display: inline-block;
              color: #282828 !important; }
              @media (min-width: 992px) {
                header .header-menu ul li ul li a {
                  padding: 15px 0; } }
              header .header-menu ul li ul li a:after {
                content: "";
                width: 0%;
                height: 1px;
                position: absolute;
                bottom: 13px;
                left: 0;
                background: #282828;
                -moz-transition: all ease-in-out 200ms;
                -o-transition: all ease-in-out 200ms;
                -webkit-transition: all ease-in-out 200ms;
                transition: all ease-in-out 200ms; }
              header .header-menu ul li ul li a:focus:after, header .header-menu ul li ul li a:hover:after {
                width: 100%; }
          header .header-menu ul li.hovered > a, header .header-menu ul li:hover > a {
            color: #eb140a; }
          header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -webkit-transform: translateY(0%) rotate(180deg);
            -moz-transform: translateY(0%) rotate(180deg);
            -ms-transform: translateY(0%) rotate(180deg);
            -o-transform: translateY(0%) rotate(180deg);
            transform: translateY(0%) rotate(180deg); }
          header .header-menu ul li.open ul {
            display: block; }
      header .header-menu .menu-openner {
        position: absolute;
        top: 22.5px;
        right: 15rem;
        color: #282828;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 6rem;
        height: 6rem;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center; }
        @media (min-width: 545px) {
          header .header-menu .menu-openner {
            right: 12rem; } }
        @media (min-width: 768px) {
          header .header-menu .menu-openner {
            right: 9rem; } }
        @media (min-width: 992px) {
          header .header-menu .menu-openner {
            display: none; } }
        header .header-menu .menu-openner:after {
          content: "\e900";
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          font-size: 6px;
          position: absolute; }
    @media (min-width: 992px) {
      header .header-hamburger {
        display: none; } }
    header .header-hamburger .open-menu {
      width: 26px;
      height: 26px;
      position: relative;
      display: block; }
      header .header-hamburger .open-menu span {
        width: 26px;
        position: absolute;
        left: 0.5rem;
        height: 3px;
        background: #a5a5a5;
        margin-top: -1.5px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        border-radius: 2px;
        top: 50%;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
          content: "";
          width: 26px;
          left: 0;
          height: 3px;
          background: #a5a5a5;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          -ms-border-radius: 2px;
          border-radius: 2px;
          position: absolute;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
        header .header-hamburger .open-menu span:before {
          top: -8px; }
        header .header-hamburger .open-menu span:after {
          bottom: -8px; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent;
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px);
      transform: translateX(100px); }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -webkit-transform: translateX(-100px) rotate(45deg);
        -moz-transform: translateX(-100px) rotate(45deg);
        -ms-transform: translateX(-100px) rotate(45deg);
        -o-transform: translateX(-100px) rotate(45deg);
        transform: translateX(-100px) rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -webkit-transform: translateX(-100px) rotate(-45deg);
        -moz-transform: translateX(-100px) rotate(-45deg);
        -ms-transform: translateX(-100px) rotate(-45deg);
        -o-transform: translateX(-100px) rotate(-45deg);
        transform: translateX(-100px) rotate(-45deg); }
    header .header.open .header-menu {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      -o-transform: translateX(0px);
      transform: translateX(0px); }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  background: #282828;
  padding: 20px 0; }
  @media (min-width: 992px) {
    footer {
      padding: 40px 0; } }
  footer .footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    color: #fff;
    font-size: 15px;
    line-height: 25px; }
    @media (min-width: 1200px) {
      footer .footer {
        font-size: 16px;
        line-height: 26px; } }
    footer .footer a {
      color: #fff; }
      footer .footer a:hover, footer .footer a:focus {
        color: #eb140a; }
    footer .footer div {
      padding: 10px 0;
      width: 100%;
      text-align: center; }
      @media (min-width: 768px) {
        footer .footer div {
          width: 25%;
          text-align: left; } }
      @media (min-width: 992px) {
        footer .footer div {
          padding: 0;
          width: auto;
          position: relative;
          z-index: 3; } }
      footer .footer div big {
        font-size: 18px;
        padding-bottom: 5px;
        line-height: 28px; }
        @media (min-width: 1200px) {
          footer .footer div big {
            font-size: 20px;
            line-height: 30px; } }
    @media (min-width: 768px) {
      footer .footer .footer-logo {
        width: 100%; } }
    @media (min-width: 992px) {
      footer .footer .footer-logo {
        width: auto;
        padding-right: 10rem; } }
    @media (min-width: 1200px) {
      footer .footer .footer-logo {
        padding-right: 12rem; } }
    footer .footer .footer-logo img {
      width: 262px;
      height: 60px; }
    footer .footer .footer-column {
      padding-right: 2rem; }
      footer .footer .footer-column:last-child {
        width: 100%;
        position: relative;
        font-size: 13px;
        padding-top: 30px; }
        @media (min-width: 768px) {
          footer .footer .footer-column:last-child {
            padding-top: 10px; } }
        @media (min-width: 992px) {
          footer .footer .footer-column:last-child {
            font-size: 14px;
            padding-top: 0;
            position: relative;
            top: -26px;
            z-index: 1; } }

.is-sm {
  display: none; }
  @media (min-width: 545px) {
    .is-sm {
      display: block; } }

.is-md {
  display: none; }
  @media (min-width: 768px) {
    .is-md {
      display: block; } }

.is-lg {
  display: none; }
  @media (min-width: 992px) {
    .is-lg {
      display: block; } }

.is-xl {
  display: none; }
  @media (min-width: 1200px) {
    .is-xl {
      display: block; } }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.2s ease-out forwards; }

@font-face {
  font-family: Moto Sans;
  src: url(./fonts/MotoSansWeb-Regular.ttf);
  font-weight: 400; }

@font-face {
  font-family: Moto Sans;
  src: url(./fonts/MotoSansWeb-Light.ttf);
  font-weight: 300; }

@font-face {
  font-family: Moto Sans;
  src: url(./fonts/MotoSansWeb-Semibold.ttf);
  font-weight: 500; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "Moto Sans", sans-serif;
  height: 100%;
  color: #282828; }

@media (min-width: 320px) {
  body {
    font-size: 3rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.72727rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  body {
    font-size: 2rem; } }

@media (min-width: 992px) {
  body {
    font-size: 1.875rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.66667rem; } }

@media (min-width: 1400px) {
  body {
    font-size: 1.6rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  font-size: 45px;
  line-height: 1.5;
  padding-bottom: 2rem; }
  @media (min-width: 1400px) {
    h1,
    .h1 {
      font-size: 50px; } }

h2,
.h2 {
  padding-bottom: 2rem; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 6rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 5.45455rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 5rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 4rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 7rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 6.36364rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 5.83333rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 4.66667rem; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      line-height: 4.375rem; } }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      line-height: 4rem; } }

@media (min-width: 320px) {
  h3,
  .h3 {
    font-size: 5.2rem; } }

@media (min-width: 480px) {
  h3,
  .h3 {
    font-size: 4.72727rem; } }

@media (min-width: 545px) {
  h3,
  .h3 {
    font-size: 4.33333rem; } }

@media (min-width: 768px) {
  h3,
  .h3 {
    font-size: 3.46667rem; } }

@media (min-width: 992px) {
  h3,
  .h3 {
    font-size: 3.25rem; } }

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 3rem; } }

@media (min-width: 320px) {
  h3,
  .h3 {
    line-height: 5.2rem; } }

@media (min-width: 480px) {
  h3,
  .h3 {
    line-height: 4.72727rem; } }

@media (min-width: 545px) {
  h3,
  .h3 {
    line-height: 4.33333rem; } }

@media (min-width: 768px) {
  h3,
  .h3 {
    line-height: 3.46667rem; } }

@media (min-width: 992px) {
  h3,
  .h3 {
    line-height: 3.25rem; } }

@media (min-width: 1200px) {
  h3,
  .h3 {
    line-height: 3rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    font-size: 4.6rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    font-size: 4.18182rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    font-size: 3.83333rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 3.06667rem; } }

@media (min-width: 992px) {
  h4,
  .h4 {
    font-size: 2.875rem; } }

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 2.55556rem; } }

@media (min-width: 1400px) {
  h4,
  .h4 {
    font-size: 2.5rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    line-height: 4.6rem; } }

@media (min-width: 480px) {
  h4,
  .h4 {
    line-height: 4.18182rem; } }

@media (min-width: 545px) {
  h4,
  .h4 {
    line-height: 3.83333rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    line-height: 3.06667rem; } }

@media (min-width: 992px) {
  h4,
  .h4 {
    line-height: 2.875rem; } }

@media (min-width: 1200px) {
  h4,
  .h4 {
    line-height: 2.55556rem; } }

@media (min-width: 1400px) {
  h4,
  .h4 {
    line-height: 2.5rem; } }

big,
.perex {
  font-weight: 500; }
  @media (min-width: 320px) {
    big,
    .perex {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    big,
    .perex {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      font-size: 2rem; } }
  @media (min-width: 320px) {
    big,
    .perex {
      line-height: 4.4rem; } }
  @media (min-width: 480px) {
    big,
    .perex {
      line-height: 4rem; } }
  @media (min-width: 545px) {
    big,
    .perex {
      line-height: 3.66667rem; } }
  @media (min-width: 768px) {
    big,
    .perex {
      line-height: 2.93333rem; } }
  @media (min-width: 992px) {
    big,
    .perex {
      line-height: 2.75rem; } }
  @media (min-width: 1200px) {
    big,
    .perex {
      line-height: 2.6rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.6rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 3.27273rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 3rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.4rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 2.25rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 1.8rem; } }

a {
  color: #eb140a;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  cursor: pointer; }
  a:focus, a:hover {
    color: #ba1008;
    outline: none;
    text-decoration: none; }

.btn {
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 1.5rem 4.5rem 1.5rem 5rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 23px;
  font-weight: 300;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px; }
  @media (min-width: 1200px) {
    .btn {
      font-size: 25px; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.btn-primary {
  background: #eb140a;
  color: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }
  .btn-primary:focus, .btn-primary:hover {
    text-decoration: none;
    color: #fff;
    background: #eb140a;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); }
  .btn-primary:after {
    content: "\e903";
    color: #fff;
    padding-left: 8px;
    font-size: 16px; }

.btn-default {
  background: #b7b7b7;
  color: #282828; }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none;
    background: #aaaaaa;
    color: #282828; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem;
  padding-top: 9rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 2rem 0;
      padding: 0 0 0 2rem; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        color: #eb140a;
        position: absolute;
        left: 0;
        top: 3px;
        font-weight: bold;
        font-family: "Moto Sans", sans-serif !important; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 5px 0;
      padding: 0 0 0 14px;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        background: #bbbbbb;
        position: absolute;
        top: 8px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr {
      border-bottom: 1px solid #f1f1f1; }
      main table tr td {
        padding: 1rem 1.5rem; }
      main table tr:nth-child(even) {
        background: #a3a3a3; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr {
        border-bottom: 1px solid #f1f1f1; }
        main .respo-table table thead tr th {
          font-weight: bold;
          padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border: 1px solid #f1f1f1;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      main .respo-table table tbody tr:nth-child(even) {
        background: #a3a3a3; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none;
          border-bottom: 1px solid #f1f1f1; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #f1f1f1; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "Moto Sans", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 2.8rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.4rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  border: 1px solid #e6e6e6;
  background: #fff;
  margin: 16px 0;
  color: #282828;
  font-size: 2.8rem;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 9px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.03); }
  @media (min-width: 320px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.4rem; } }
  .form-control::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  textarea.form-control::-webkit-input-placeholder,
  input[type="text"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder {
    color: #000; }
  .form-control:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control:-moz-placeholder,
  textarea:-moz-placeholder,
  textarea.form-control:-moz-placeholder,
  input[type="text"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder {
    color: #000; }
  .form-control::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control::-moz-placeholder,
  textarea::-moz-placeholder,
  textarea.form-control::-moz-placeholder,
  input[type="text"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder {
    color: #000; }
  .form-control:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  textarea.form-control:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder {
    color: #000; }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border: 1px solid #e6e6e6;
    -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.3); }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }
  textarea.form-control:focus,
  textarea:focus {
    border: 1px solid #e6e6e6; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  color: #eb140a;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  background: #eb140a;
  cursor: pointer;
  font-size: 2.8rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem;
  background: #b7b7b7;
  color: #282828; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 2.8rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.4rem; } }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #eb140a;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:before {
    border: 1px solid #eb140a; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #eeeff4;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      border: 1px solid #eeeff4;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      background: #eb140a;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:before {
    border: 1px solid #eb140a; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.category-container {
  padding: 5rem 0 8.5rem; }

.row.category, .row.support, .row.dealers {
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .row.category [class^="col-"]:nth-child(2) .category-item,
    .row.category [class^="col-"]:nth-child(2) .product-support-item,
    .row.category [class^="col-"]:nth-child(2) .dealers-item,
    .row.category [class^="col-"]:nth-child(2) .eshop-item, .row.support [class^="col-"]:nth-child(2) .category-item,
    .row.support [class^="col-"]:nth-child(2) .product-support-item,
    .row.support [class^="col-"]:nth-child(2) .dealers-item,
    .row.support [class^="col-"]:nth-child(2) .eshop-item, .row.dealers [class^="col-"]:nth-child(2) .category-item,
    .row.dealers [class^="col-"]:nth-child(2) .product-support-item,
    .row.dealers [class^="col-"]:nth-child(2) .dealers-item,
    .row.dealers [class^="col-"]:nth-child(2) .eshop-item {
      -webkit-border-radius: 0 7px 0 0;
      -moz-border-radius: 0 7px 0 0;
      -ms-border-radius: 0 7px 0 0;
      border-radius: 0 7px 0 0; } }
  @media (min-width: 992px) {
    .row.category [class^="col-"]:nth-child(2) .category-item,
    .row.category [class^="col-"]:nth-child(2) .product-support-item,
    .row.category [class^="col-"]:nth-child(2) .dealers-item,
    .row.category [class^="col-"]:nth-child(2) .eshop-item, .row.support [class^="col-"]:nth-child(2) .category-item,
    .row.support [class^="col-"]:nth-child(2) .product-support-item,
    .row.support [class^="col-"]:nth-child(2) .dealers-item,
    .row.support [class^="col-"]:nth-child(2) .eshop-item, .row.dealers [class^="col-"]:nth-child(2) .category-item,
    .row.dealers [class^="col-"]:nth-child(2) .product-support-item,
    .row.dealers [class^="col-"]:nth-child(2) .dealers-item,
    .row.dealers [class^="col-"]:nth-child(2) .eshop-item {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; } }
  @media (min-width: 768px) {
    .row.category [class^="col-"]:nth-child(3) .category-item,
    .row.category [class^="col-"]:nth-child(3) .product-support-item,
    .row.category [class^="col-"]:nth-child(3) .dealers-item,
    .row.category [class^="col-"]:nth-child(3) .eshop-item, .row.support [class^="col-"]:nth-child(3) .category-item,
    .row.support [class^="col-"]:nth-child(3) .product-support-item,
    .row.support [class^="col-"]:nth-child(3) .dealers-item,
    .row.support [class^="col-"]:nth-child(3) .eshop-item, .row.dealers [class^="col-"]:nth-child(3) .category-item,
    .row.dealers [class^="col-"]:nth-child(3) .product-support-item,
    .row.dealers [class^="col-"]:nth-child(3) .dealers-item,
    .row.dealers [class^="col-"]:nth-child(3) .eshop-item {
      -webkit-border-radius: 0 0 0 7px;
      -moz-border-radius: 0 0 0 7px;
      -ms-border-radius: 0 0 0 7px;
      border-radius: 0 0 0 7px; } }
  @media (min-width: 992px) {
    .row.category [class^="col-"]:nth-child(3) .category-item,
    .row.category [class^="col-"]:nth-child(3) .product-support-item,
    .row.category [class^="col-"]:nth-child(3) .dealers-item,
    .row.category [class^="col-"]:nth-child(3) .eshop-item, .row.support [class^="col-"]:nth-child(3) .category-item,
    .row.support [class^="col-"]:nth-child(3) .product-support-item,
    .row.support [class^="col-"]:nth-child(3) .dealers-item,
    .row.support [class^="col-"]:nth-child(3) .eshop-item, .row.dealers [class^="col-"]:nth-child(3) .category-item,
    .row.dealers [class^="col-"]:nth-child(3) .product-support-item,
    .row.dealers [class^="col-"]:nth-child(3) .dealers-item,
    .row.dealers [class^="col-"]:nth-child(3) .eshop-item {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; } }
  .row.category [class^="col-"]:first-child .category-item,
  .row.category [class^="col-"]:first-child .product-support-item,
  .row.category [class^="col-"]:first-child .dealers-item,
  .row.category [class^="col-"]:first-child .eshop-item, .row.support [class^="col-"]:first-child .category-item,
  .row.support [class^="col-"]:first-child .product-support-item,
  .row.support [class^="col-"]:first-child .dealers-item,
  .row.support [class^="col-"]:first-child .eshop-item, .row.dealers [class^="col-"]:first-child .category-item,
  .row.dealers [class^="col-"]:first-child .product-support-item,
  .row.dealers [class^="col-"]:first-child .dealers-item,
  .row.dealers [class^="col-"]:first-child .eshop-item {
    -webkit-border-radius: 7px 7px 0 0;
    -moz-border-radius: 7px 7px 0 0;
    -ms-border-radius: 7px 7px 0 0;
    border-radius: 7px 7px 0 0; }
    @media (min-width: 768px) {
      .row.category [class^="col-"]:first-child .category-item,
      .row.category [class^="col-"]:first-child .product-support-item,
      .row.category [class^="col-"]:first-child .dealers-item,
      .row.category [class^="col-"]:first-child .eshop-item, .row.support [class^="col-"]:first-child .category-item,
      .row.support [class^="col-"]:first-child .product-support-item,
      .row.support [class^="col-"]:first-child .dealers-item,
      .row.support [class^="col-"]:first-child .eshop-item, .row.dealers [class^="col-"]:first-child .category-item,
      .row.dealers [class^="col-"]:first-child .product-support-item,
      .row.dealers [class^="col-"]:first-child .dealers-item,
      .row.dealers [class^="col-"]:first-child .eshop-item {
        -webkit-border-radius: 7px 0 0;
        -moz-border-radius: 7px 0 0;
        -ms-border-radius: 7px 0 0;
        border-radius: 7px 0 0; } }
    @media (min-width: 992px) {
      .row.category [class^="col-"]:first-child .category-item,
      .row.category [class^="col-"]:first-child .product-support-item,
      .row.category [class^="col-"]:first-child .dealers-item,
      .row.category [class^="col-"]:first-child .eshop-item, .row.support [class^="col-"]:first-child .category-item,
      .row.support [class^="col-"]:first-child .product-support-item,
      .row.support [class^="col-"]:first-child .dealers-item,
      .row.support [class^="col-"]:first-child .eshop-item, .row.dealers [class^="col-"]:first-child .category-item,
      .row.dealers [class^="col-"]:first-child .product-support-item,
      .row.dealers [class^="col-"]:first-child .dealers-item,
      .row.dealers [class^="col-"]:first-child .eshop-item {
        -webkit-border-radius: 7px 0 0 7px;
        -moz-border-radius: 7px 0 0 7px;
        -ms-border-radius: 7px 0 0 7px;
        border-radius: 7px 0 0 7px; } }
  .row.category [class^="col-"]:last-child .category-item,
  .row.category [class^="col-"]:last-child .product-support-item,
  .row.category [class^="col-"]:last-child .dealers-item,
  .row.category [class^="col-"]:last-child .eshop-item, .row.support [class^="col-"]:last-child .category-item,
  .row.support [class^="col-"]:last-child .product-support-item,
  .row.support [class^="col-"]:last-child .dealers-item,
  .row.support [class^="col-"]:last-child .eshop-item, .row.dealers [class^="col-"]:last-child .category-item,
  .row.dealers [class^="col-"]:last-child .product-support-item,
  .row.dealers [class^="col-"]:last-child .dealers-item,
  .row.dealers [class^="col-"]:last-child .eshop-item {
    -webkit-border-radius: 0 0 7px 7px;
    -moz-border-radius: 0 0 7px 7px;
    -ms-border-radius: 0 0 7px 7px;
    border-radius: 0 0 7px 7px; }
    @media (min-width: 768px) {
      .row.category [class^="col-"]:last-child .category-item,
      .row.category [class^="col-"]:last-child .product-support-item,
      .row.category [class^="col-"]:last-child .dealers-item,
      .row.category [class^="col-"]:last-child .eshop-item, .row.support [class^="col-"]:last-child .category-item,
      .row.support [class^="col-"]:last-child .product-support-item,
      .row.support [class^="col-"]:last-child .dealers-item,
      .row.support [class^="col-"]:last-child .eshop-item, .row.dealers [class^="col-"]:last-child .category-item,
      .row.dealers [class^="col-"]:last-child .product-support-item,
      .row.dealers [class^="col-"]:last-child .dealers-item,
      .row.dealers [class^="col-"]:last-child .eshop-item {
        -webkit-border-radius: 0 0 7px;
        -moz-border-radius: 0 0 7px;
        -ms-border-radius: 0 0 7px;
        border-radius: 0 0 7px; } }
    @media (min-width: 992px) {
      .row.category [class^="col-"]:last-child .category-item,
      .row.category [class^="col-"]:last-child .product-support-item,
      .row.category [class^="col-"]:last-child .dealers-item,
      .row.category [class^="col-"]:last-child .eshop-item, .row.support [class^="col-"]:last-child .category-item,
      .row.support [class^="col-"]:last-child .product-support-item,
      .row.support [class^="col-"]:last-child .dealers-item,
      .row.support [class^="col-"]:last-child .eshop-item, .row.dealers [class^="col-"]:last-child .category-item,
      .row.dealers [class^="col-"]:last-child .product-support-item,
      .row.dealers [class^="col-"]:last-child .dealers-item,
      .row.dealers [class^="col-"]:last-child .eshop-item {
        -webkit-border-radius: 0 7px 7px 0;
        -moz-border-radius: 0 7px 7px 0;
        -ms-border-radius: 0 7px 7px 0;
        border-radius: 0 7px 7px 0; } }

.category-item {
  padding: 30px 40px 60px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: #fff; }
  .category-item-image {
    height: 140px;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 1400px) {
      .category-item-image {
        height: 150px; } }
    .category-item-image img {
      margin: auto; }
  .category-item-name {
    text-align: center;
    font-size: 23px;
    color: #282828;
    font-weight: 500; }
    @media (min-width: 1400px) {
      .category-item-name {
        font-size: 25px; } }
  .category-item:hover, .category-item:focus {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    position: relative;
    z-index: 2; }
    .category-item:hover .category-item-name, .category-item:focus .category-item-name {
      color: #282828; }

.product-list {
  padding: 6rem 0; }

.product-item {
  padding-bottom: 20px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  color: #282828; }
  @media (min-width: 768px) {
    .product-item {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .product-item:hover, .product-item:focus {
    color: #282828; }
    .product-item:hover .product-item-image,
    .product-item:hover .product-item-content, .product-item:focus .product-item-image,
    .product-item:focus .product-item-content {
      -webkit-box-shadow: 0 0 24px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0 0 24px rgba(0, 0, 0, 0.35);
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.35); }
  .product-item:last-child {
    padding-bottom: 0; }
  .product-item-image {
    height: 313px;
    max-width: 367px;
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 2;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 1400px) {
      .product-item-image {
        max-width: 400px;
        height: 340px; } }
    .product-item-image.archived-product img {
      filter: grayscale(100%); }
  .product-item-content {
    width: 100%;
    max-width: 330px;
    background: #fff;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 768px) {
      .product-item-content {
        max-width: 395px; } }
    @media (min-width: 992px) {
      .product-item-content {
        max-width: 440px; } }
    @media (min-width: 1400px) {
      .product-item-content {
        max-width: 480px; } }
    .product-item-content-head {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding: 30px 15px 30px 50px;
      -webkit-border-radius: 7px 7px 0 0;
      -moz-border-radius: 7px 7px 0 0;
      -ms-border-radius: 7px 7px 0 0;
      border-radius: 7px 7px 0 0;
      font-weight: 500;
      font-size: 23px;
      background: #eeeeee; }
      @media (min-width: 768px) {
        .product-item-content-head {
          padding: 30px 10px 30px 65px; } }
      @media (min-width: 992px) {
        .product-item-content-head {
          padding: 30px 25px 30px 65px; } }
      @media (min-width: 1400px) {
        .product-item-content-head {
          font-size: 25px;
          padding: 30px  25px 30px 70px; } }
    .product-item-content-body {
      font-weight: 300;
      padding: 20px 20px 0px 54px; }
      @media (min-width: 768px) {
        .product-item-content-body {
          padding: 20px 20px 0px 54px; } }
      @media (min-width: 992px) {
        .product-item-content-body {
          padding: 20px 25px 0px 65px; } }
      @media (min-width: 1400px) {
        .product-item-content-body {
          padding: 23px 25px 0px 70px; } }
    .product-item-content-price {
      font-weight: 300;
      line-height: 1;
      padding: 20px 20px 35px 68px; }
      @media (min-width: 768px) {
        .product-item-content-price {
          padding: 20px 20px 35px 68px; } }
      @media (min-width: 992px) {
        .product-item-content-price {
          padding: 20px 25px 25px 79px; } }
      @media (min-width: 1400px) {
        .product-item-content-price {
          padding: 25px 25px 35px 84px; } }
      .product-item-content-price span {
        display: block;
        padding-top: 5px;
        letter-spacing: 0.44px;
        font-size: 30px;
        font-weight: 500; }
        @media (min-width: 1400px) {
          .product-item-content-price span {
            font-size: 34px; } }

.product-detail {
  padding: 6.5rem 0 4.5rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  line-height: 19px;
  font-weight: 300;
  position: relative; }
  @media (min-width: 1400px) {
    .product-detail {
      line-height: 21px; } }
  .product-detail-360 {
    /*
    position: absolute;
    left: 3rem;
    top: 9.5rem;
    z-index: 20;
    a:before{
      font-size: 30px;
      color: $main-color;
    }*/ }
    .product-detail-360-iframe {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6rem 0 3rem;
      width: 100%;
      position: relative;
      padding-top: 100%; }
      @media (min-width: 480px) {
        .product-detail-360-iframe {
          padding-top: 60%; } }
      @media (min-width: 768px) {
        .product-detail-360-iframe {
          padding-top: 40%; } }
      .product-detail-360-iframe iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.85);
        height: 100%;
        width: 320px;
        border: 0; }
        @media (min-width: 480px) {
          .product-detail-360-iframe iframe {
            transform: translate(-50%, -50%);
            width: 100%; } }
        @media (min-width: 992px) {
          .product-detail-360-iframe iframe {
            max-width: 60rem; } }
  .product-detail-gallery {
    width: 100%;
    text-align: center;
    margin-bottom: 60px; }
    .product-detail-gallery .slick-arrow {
      position: absolute;
      top: 50%;
      margin-top: -4.25rem;
      width: 3rem;
      height: 8.5rem;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      border: none;
      font-size: 0;
      background: transparent;
      outline: 0;
      z-index: 22;
      opacity: 0.61;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=61)";
      filter: alpha(opacity=61);
      cursor: pointer;
      padding: 0; }
      .product-detail-gallery .slick-arrow:before {
        font-size: 8.5rem; }
      .product-detail-gallery .slick-arrow.slick-prev {
        left: -4rem; }
        @media (min-width: 992px) {
          .product-detail-gallery .slick-arrow.slick-prev {
            left: -55px; } }
        .product-detail-gallery .slick-arrow.slick-prev:before {
          color: #7f8080;
          content: ""; }
      .product-detail-gallery .slick-arrow.slick-next {
        right: -4rem; }
        @media (min-width: 768px) {
          .product-detail-gallery .slick-arrow.slick-next {
            right: 0rem; } }
        @media (min-width: 992px) {
          .product-detail-gallery .slick-arrow.slick-next {
            right: 5px; } }
        .product-detail-gallery .slick-arrow.slick-next:before {
          color: #7f8080;
          content: ""; }
    .product-detail-gallery .slick-dots {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      margin: auto;
      padding-left: 25px;
      padding-right: 25px; }
      @media (min-width: 768px) {
        .product-detail-gallery .slick-dots {
          padding-left: 40px;
          padding-right: 40px; } }
      @media (min-width: 992px) {
        .product-detail-gallery .slick-dots {
          padding-left: 15.5rem;
          padding-right: 15.5rem; } }
      .product-detail-gallery .slick-dots li {
        margin: 0;
        padding: 0 2rem 0 0; }
        .product-detail-gallery .slick-dots li:before {
          display: none; }
        .product-detail-gallery .slick-dots li:last-child {
          padding: 0; }
        .product-detail-gallery .slick-dots li:first-child:last-child {
          display: none; }
        .product-detail-gallery .slick-dots li button {
          font-size: 0;
          outline: 0;
          width: 5rem;
          height: 5rem;
          border: 6px solid #ffffff;
          background: transparent;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          padding: 0;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center; }
          @media (min-width: 480px) {
            .product-detail-gallery .slick-dots li button {
              width: 4.54545rem; } }
          @media (min-width: 545px) {
            .product-detail-gallery .slick-dots li button {
              width: 4.16667rem; } }
          @media (min-width: 768px) {
            .product-detail-gallery .slick-dots li button {
              width: 3.33333rem; } }
          @media (min-width: 992px) {
            .product-detail-gallery .slick-dots li button {
              width: 3.125rem; } }
          @media (min-width: 1200px) {
            .product-detail-gallery .slick-dots li button {
              width: 2.77778rem; } }
          @media (min-width: 1400px) {
            .product-detail-gallery .slick-dots li button {
              width: 2.5rem; } }
          @media (min-width: 480px) {
            .product-detail-gallery .slick-dots li button {
              height: 4.54545rem; } }
          @media (min-width: 545px) {
            .product-detail-gallery .slick-dots li button {
              height: 4.16667rem; } }
          @media (min-width: 768px) {
            .product-detail-gallery .slick-dots li button {
              height: 3.33333rem; } }
          @media (min-width: 992px) {
            .product-detail-gallery .slick-dots li button {
              height: 3.125rem; } }
          @media (min-width: 1200px) {
            .product-detail-gallery .slick-dots li button {
              height: 2.77778rem; } }
          @media (min-width: 1400px) {
            .product-detail-gallery .slick-dots li button {
              height: 2.5rem; } }
        .product-detail-gallery .slick-dots li.slick-active button {
          background: #fff; }
    @media (min-width: 768px) {
      .product-detail-gallery {
        width: 55%;
        padding-right: 30px; } }
    @media (min-width: 992px) {
      .product-detail-gallery {
        padding-right: 55px; } }
    @media (min-width: 1400px) {
      .product-detail-gallery {
        padding-right: 65px; } }
    .product-detail-gallery .slick-list {
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      -ms-border-radius: 7px;
      border-radius: 7px;
      -webkit-box-shadow: 0 0 24px rgba(0, 0, 0, 0.06);
      -moz-box-shadow: 0 0 24px rgba(0, 0, 0, 0.06);
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.06); }
    .product-detail-gallery .slick-cloned, .product-detail-gallery .Pager {
      display: none; }
    .product-detail-gallery .gallery-item {
      background: #fff;
      height: 350px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      @media (min-width: 768px) {
        .product-detail-gallery .gallery-item {
          height: 350px; } }
      @media (min-width: 992px) {
        .product-detail-gallery .gallery-item {
          height: 523px; } }
      @media (min-width: 1400px) {
        .product-detail-gallery .gallery-item {
          height: 575px; } }
    .product-detail-gallery .slick-dots {
      bottom: -45px; }
      @media (min-width: 768px) {
        .product-detail-gallery .slick-dots {
          -webkit-transform: translateX(-15px);
          -moz-transform: translateX(-15px);
          -ms-transform: translateX(-15px);
          -o-transform: translateX(-15px);
          transform: translateX(-15px); } }
      @media (min-width: 992px) {
        .product-detail-gallery .slick-dots {
          -webkit-transform: translateX(-27.5px);
          -moz-transform: translateX(-27.5px);
          -ms-transform: translateX(-27.5px);
          -o-transform: translateX(-27.5px);
          transform: translateX(-27.5px); } }
      @media (min-width: 1400px) {
        .product-detail-gallery .slick-dots {
          -webkit-transform: translateX(-32.5px);
          -moz-transform: translateX(-32.5px);
          -ms-transform: translateX(-32.5px);
          -o-transform: translateX(-32.5px);
          transform: translateX(-32.5px); } }
      .product-detail-gallery .slick-dots li button {
        border: 4px solid #bbbbbb;
        width: 15px;
        height: 15px; }
      .product-detail-gallery .slick-dots li.slick-active button {
        background: #bbbbbb; }
  .product-detail-description {
    width: 100%; }
    @media (min-width: 768px) {
      .product-detail-description {
        width: 45%;
        padding-left: 30px; } }
    @media (min-width: 992px) {
      .product-detail-description {
        padding-left: 55px; } }
    @media (min-width: 1400px) {
      .product-detail-description {
        padding-left: 65px; } }
    .product-detail-description-title {
      text-transform: uppercase; }
    .product-detail-description-perex {
      padding-bottom: 35px; }
    .product-detail-description-images {
      padding: 45px 0; }
      .product-detail-description-images img {
        margin-right: 20px; }
    .product-detail-description-price {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .product-detail-description-price-btn {
        padding-right: 25px;
        display: inline-block; }
        .product-detail-description-price-btn .btn {
          padding: 1.5rem 3.5rem 1.5rem 4rem; }
      .product-detail-description-price-text {
        line-height: 1; }
        .product-detail-description-price-text span {
          display: block;
          padding-top: 5px;
          letter-spacing: 0.44px;
          font-size: 30px;
          font-weight: 500; }
          @media (min-width: 1400px) {
            .product-detail-description-price-text span {
              font-size: 34px; } }

.gray-bgr {
  background: #f6f6f6; }

.detail-description {
  padding: 5rem 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .detail-description-content {
    width: 100%;
    text-align: justify;
    font-weight: 300; }
    @media (min-width: 992px) {
      .detail-description-content {
        padding-left: 15px;
        width: 50%; } }
    .detail-description-content h1, .detail-description-content h2, .detail-description-content h3, .detail-description-content h4 {
      padding-bottom: 1.5rem; }
  .detail-description-image {
    width: 100%;
    padding: 50px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .detail-description-image {
        padding: 15px;
        width: 50%; } }
  @media (min-width: 992px) {
    .detail-description.reversed .detail-description-content {
      order: 2; } }
  @media (min-width: 992px) {
    .detail-description.reversed .detail-description-image {
      order: 1; } }

.detail-video {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 250px; }
  @media (min-width: 768px) {
    .detail-video {
      height: 400px; } }
  @media (min-width: 992px) {
    .detail-video {
      height: 600px; } }
  @media (min-width: 1400px) {
    .detail-video {
      height: 660px; } }
  .detail-video a {
    font-size: 75px;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    .detail-video a:focus, .detail-video a:hover {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }

.detail-hubble {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-top: 70px; }
  @media (min-width: 768px) {
    .detail-hubble {
      padding-top: 140px;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; } }
  @media (min-width: 992px) {
    .detail-hubble {
      padding: 0 200px;
      padding-top: 85px; } }
  @media (min-width: 1400px) {
    .detail-hubble {
      padding-top: 90px; } }
  .detail-hubble-content {
    width: 100%;
    max-width: 350px; }
    @media (min-width: 768px) {
      .detail-hubble-content {
        width: auto; } }
    @media (min-width: 1400px) {
      .detail-hubble-content {
        max-width: 390px; } }
    .detail-hubble-content-perex {
      padding: 25px 0 30px;
      font-weight: 300; }
      @media (min-width: 1400px) {
        .detail-hubble-content-perex {
          padding: 30px 0; } }
    .detail-hubble-content-btns {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin-bottom: 35px; }
      @media (min-width: 768px) {
        .detail-hubble-content-btns {
          margin-left: -10px; } }
      @media (min-width: 1400px) {
        .detail-hubble-content-btns {
          margin-left: -15px; } }
      .detail-hubble-content-btns a:hover,
      .detail-hubble-content-btns a:focus {
        -webkit-box-shadow: 0 0 6px black;
        -moz-box-shadow: 0 0 6px black;
        box-shadow: 0 0 6px black; }
      .detail-hubble-content-btns .btn-app {
        overflow: hidden;
        background: #000;
        -webkit-border-radius: 1000px;
        -moz-border-radius: 1000px;
        -ms-border-radius: 1000px;
        border-radius: 1000px;
        height: 50px;
        padding: 0 20px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        margin-right: 10px; }
        @media (min-width: 768px) {
          .detail-hubble-content-btns .btn-app {
            height: 60px; } }
        @media (min-width: 1400px) {
          .detail-hubble-content-btns .btn-app {
            height: 70px; } }
        .detail-hubble-content-btns .btn-app img {
          width: 150px;
          height: 150px; }
    .detail-hubble-content-url {
      color: #679ac2; }
      .detail-hubble-content-url:focus, .detail-hubble-content-url:hover {
        color: #38678b; }

.detail-download {
  padding: 8.5rem 0 10rem; }
  .detail-download .h4 {
    padding-bottom: 2rem; }
  .detail-download-content {
    padding-left: 10px; }
    .detail-download-content-btn {
      margin: 10px 0px 10px -14px;
      display: inline-block; }
      .detail-download-content-btn .btn {
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px; }
        @media (min-width: 1400px) {
          .detail-download-content-btn .btn {
            font-size: 16px;
            padding-right: 25px;
            padding-left: 25px; } }
        .detail-download-content-btn .btn:after {
          font-size: 11px; }
  .detail-download li {
    display: block !important;
    padding-left: 14px !important;
    padding-right: 10px !important; }

.contact-container {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 4rem 0 10rem; }
  @media (min-width: 768px) {
    .contact-container {
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; } }
  .contact-container .form-box {
    background: #fff;
    width: 100%;
    padding: 50px 55px 30px;
    -webkit-box-shadow: 0 0 18px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0 0 18px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    border-radius: 7px; }
    @media (min-width: 768px) {
      .contact-container .form-box {
        padding: 40px 70px 35px;
        width: 70%; } }
    @media (min-width: 992px) {
      .contact-container .form-box {
        padding: 55px 85px 35px;
        max-width: 780px; } }
    @media (min-width: 1400px) {
      .contact-container .form-box {
        padding: 60px 85px 40px;
        max-width: 820px; } }
    .contact-container .form-box h1, .contact-container .form-box h2, .contact-container .form-box h3, .contact-container .form-box h4 {
      padding-bottom: 10px; }
    .contact-container .form-box-text {
      text-align: center;
      font-weight: 300; }
      @media (min-width: 992px) {
        .contact-container .form-box-text {
          text-align: left;
          max-width: 365px; } }
      @media (min-width: 1400px) {
        .contact-container .form-box-text {
          max-width: 380px; } }
    .contact-container .form-box .form {
      width: 100%;
      padding-top: 12px; }
      @media (min-width: 992px) {
        .contact-container .form-box .form-contact {
          max-width: 255px; } }
      @media (min-width: 1400px) {
        .contact-container .form-box .form-contact {
          max-width: 270px; } }
      .contact-container .form-box .form-btn {
        text-align: center; }
        @media (min-width: 768px) {
          .contact-container .form-box .form-btn {
            text-align: left; } }
        .contact-container .form-box .form-btn-inner {
          display: inline-block;
          position: relative; }
          .contact-container .form-box .form-btn-inner:after {
            position: absolute;
            top: 50%;
            right: 3rem;
            content: "\e903";
            color: #fff;
            padding-left: 8px;
            font-size: 16px;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%); }
        .contact-container .form-box .form-btn .btn {
          padding-right: 8rem; }
          @media (min-width: 768px) {
            .contact-container .form-box .form-btn .btn {
              padding-right: 5rem; } }
  .contact-container .text-box {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding-top: 45px; }
    @media (min-width: 768px) {
      .contact-container .text-box {
        padding-top: 0px;
        padding-left: 10px;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column; } }
    @media (min-width: 1400px) {
      .contact-container .text-box {
        padding-right: 50px; } }
    .contact-container .text-box-title {
      width: 100%;
      padding-bottom: 20px;
      text-align: center; }
      @media (min-width: 768px) {
        .contact-container .text-box-title {
          text-align: left; } }
    .contact-container .text-box-column {
      padding-right: 10px; }

.intro {
  max-width: 440px;
  margin: auto; }
  @media (min-width: 1400px) {
    .intro {
      max-width: 485px; } }

.row.support {
  padding: 0rem 0 10rem; }

.product-support-item {
  padding: 30px 40px 60px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: #fff;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .product-support-item-image {
    height: 140px;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 1400px) {
      .product-support-item-image {
        height: 150px; } }
    .product-support-item-image img {
      margin: auto; }
  .product-support-item-name {
    text-align: center;
    font-size: 23px;
    color: #282828;
    font-weight: 500; }
    @media (min-width: 1400px) {
      .product-support-item-name {
        font-size: 25px; } }
  .product-support-item-docs a {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    font-weight: 500;
    padding-top: 15px; }
    .product-support-item-docs a:after {
      content: "\e901";
      color: #eb140a;
      padding-left: 5px; }

.stores-btns-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  max-width: 310px;
  background: #fff;
  border: 1px solid #e6e6e6;
  -webkit-box-shadow: 0 0 9px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0 0 9px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.03);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  height: 60px;
  font-size: 15px; }
  @media (min-width: 1400px) {
    .stores-btns-row {
      font-size: 16px;
      max-width: 340px;
      height: 65px; } }
  .stores-btns-row span, .stores-btns-row a {
    width: 50%;
    height: 100%;
    color: #282828;
    font-weight: 300;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
  .stores-btns-row span {
    background: #e6e6e6; }
  .stores-btns-row a:hover, .stores-btns-row a:focus {
    color: #282828;
    background: #e6e6e6; }

.region-group {
  padding: 3rem 0; }
  .region-group h3 {
    padding-bottom: 2rem;
    color: #126caa; }

.dealers-item {
  padding: 95px 0px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: #fff;
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1400px) {
    .dealers-item {
      padding: 100px 20px; } }
  .dealers-item-name {
    text-align: center;
    font-size: 23px;
    color: #282828;
    font-weight: 500; }
    @media (min-width: 1400px) {
      .dealers-item-name {
        font-size: 25px; } }
  .dealers-item-city {
    padding: 5px 0; }
  .dealers-item a {
    color: #416ba4; }
    .dealers-item a:hover, .dealers-item a:focus {
      text-decoration: underline; }

.eshop-item {
  padding: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  height: 260px; }
  @media (min-width: 1400px) {
    .eshop-item {
      height: 290px; } }

.eshops a:hover .eshop-item,
.eshops a:focus .eshop-item {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05); }

.lang-switch ul {
  height: 20px;
  width: 30px;
  position: relative; }
  .lang-switch ul li {
    height: 20px;
    width: 30px;
    position: absolute;
    left: 0;
    top: 0; }
    .lang-switch ul li .cs-cz,
    .lang-switch ul li .sk-sk {
      font-size: 0;
      display: block;
      width: 30px;
      height: 20px;
      background-image: url("../img/header_language-CZ.svg");
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
      position: relative;
      z-index: 2; }
    .lang-switch ul li .sk-sk {
      background-image: url("../img/header_language-SK.svg"); }
    .lang-switch ul li .active {
      display: none; }

.archived-label {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff !important;
  border-radius: 50%;
  z-index: 99;
  text-shadow: 0 0 3px #282828; }

.archived-layer {
  user-select: none;
  pointer-events: none;
  filter: grayscale(100%); }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  background: #fff;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  font-weight: 900;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #282828;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 6rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .selectric .button {
        width: 5.45455rem; } }
    @media (min-width: 545px) {
      .selectric .button {
        width: 5rem; } }
    @media (min-width: 768px) {
      .selectric .button {
        width: 4rem; } }
    @media (min-width: 992px) {
      .selectric .button {
        width: 3.75rem; } }
    @media (min-width: 1200px) {
      .selectric .button {
        width: 3.33333rem; } }
    @media (min-width: 1400px) {
      .selectric .button {
        width: 3rem; } }
    .selectric .button:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
      border-top: 1.5rem solid #eb140a; }
      @media (min-width: 768px) {
        .selectric .button:before {
          width: 0;
          height: 0;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-top: 1rem solid #eb140a; } }

.selectric-focus .selectric {
  border-color: #eb140a; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #eb140a;
    border-bottom: 1px solid #eb140a; }
    .selectric-open .selectric .button {
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block;
    border-color: #eb140a;
    border-top: 1px solid #eb140a; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #e6e6e6;
  border: 2px solid #fff;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    color: #282828;
    background: #f2f2f2;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
    .selectric-items li.selected {
      color: #282828; }
    .selectric-items li.highlighted {
      color: #282828; }
    .selectric-items li:hover {
      background: #ededed; }
    .selectric-items li:nth-child(odd) {
      background: #e6e6e6; }
      .selectric-items li:nth-child(odd):hover {
        background: #e0e0e0; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    color: #282828 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none;
    color: #282828; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    background: #cccccc;
    vertical-align: middle;
    position: static;
    border: 2px solid #cccccc; }
  .list-box .selectric-scroll ul li.selected:before {
    background: #eb140a; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?8f2kke");
  src: url("fonts/icomoon.eot?8f2kke#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?8f2kke") format("woff2"), url("fonts/icomoon.ttf?8f2kke") format("truetype"), url("fonts/icomoon.woff?8f2kke") format("woff"), url("fonts/icomoon.svg?8f2kke#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-down:before {
  content: "\e900";
  color: #fff; }

.icon-download:before {
  content: "\e901"; }

.icon-play:before {
  content: "\e902";
  color: #fff; }

.icon-right:before {
  content: "\e903";
  color: #fff; }

.icon-search:before {
  content: "\e904";
  color: #bbb; }

.icon-slider-left:before {
  content: "\e905"; }

.icon-slider-right:before {
  content: "\e906"; }

.icon-360:before {
  content: "\e907";
  color: #f8b620; }

.slider-init {
  position: relative;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.23); }
  .slider-init .main-slider {
    width: 100%;
    background-repeat: no-repeat !important;
    -webkit-background-size: cover !important;
    background-size: cover !important;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
    .slider-init .main-slider-inner {
      width: 100%;
      padding: 6rem 0 60px 0;
      min-height: 570px;
      height: auto !important;
      height: 570px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .slider-init .main-slider-inner-content {
        max-width: 500px; }
        @media (min-width: 992px) {
          .slider-init .main-slider-inner-content {
            width: 50%; } }
        .slider-init .main-slider-inner-content-text {
          color: #fff; }
          .slider-init .main-slider-inner-content-text h1,
          .slider-init .main-slider-inner-content-text h2 {
            color: #fff;
            padding-bottom: 1rem; }
        .slider-init .main-slider-inner-content-btn {
          padding: 3rem 0 0 0;
          display: inline-block; }
      .slider-init .main-slider-inner .pux-container {
        width: 100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
        @media (min-width: 992px) {
          .slider-init .main-slider-inner .pux-container {
            -webkit-box-pack: space-between;
            -moz-box-pack: space-between;
            -ms-flex-pack: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between; } }
      .slider-init .main-slider-inner-image {
        width: 100%;
        text-align: center; }
        @media (min-width: 992px) {
          .slider-init .main-slider-inner-image {
            width: 50%; } }
  .slider-init .slick-dots {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    margin: auto;
    padding-left: 25px;
    padding-right: 25px; }
    @media (min-width: 768px) {
      .slider-init .slick-dots {
        padding-left: 40px;
        padding-right: 40px; } }
    @media (min-width: 992px) {
      .slider-init .slick-dots {
        padding-left: 15.5rem;
        padding-right: 15.5rem; } }
    .slider-init .slick-dots li {
      margin: 0;
      padding: 0 2rem 0 0; }
      .slider-init .slick-dots li:before {
        display: none; }
      .slider-init .slick-dots li:last-child {
        padding: 0; }
      .slider-init .slick-dots li:first-child:last-child {
        display: none; }
      .slider-init .slick-dots li button {
        font-size: 0;
        outline: 0;
        width: 5rem;
        height: 5rem;
        border: 6px solid #ffffff;
        background: transparent;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        padding: 0;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center; }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button {
            width: 4.54545rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button {
            width: 4.16667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button {
            width: 3.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button {
            width: 3.125rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button {
            width: 2.77778rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button {
            width: 2.5rem; } }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button {
            height: 4.54545rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button {
            height: 4.16667rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button {
            height: 3.33333rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button {
            height: 3.125rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button {
            height: 2.77778rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button {
            height: 2.5rem; } }
      .slider-init .slick-dots li.slick-active button {
        background: #fff; }

.top-image {
  width: 100%;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  background-position: center center !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  position: relative; }
  .top-image:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    top: 0;
    left: 0; }
  .top-image-inner {
    width: 100%;
    padding: 5rem 0;
    min-height: 350px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    z-index: 2; }
    .top-image-inner-content {
      max-width: 50rem;
      margin: auto;
      text-align: center; }
      .top-image-inner-content-text {
        color: #282828; }
        .top-image-inner-content-text h1,
        .top-image-inner-content-text h2 {
          color: #282828;
          margin: 0;
          padding: 0 0 1.5rem 0; }
      .top-image-inner-content-btn {
        padding: 20px 0 0 0; }
    .top-image-inner .pux-container {
      width: 100%; }
  .top-image.top-center {
    background-position: top center !important; }
  .top-image.top-left {
    background-position: top left !important; }
  .top-image.top-right {
    background-position: top right !important; }
  .top-image.center-center {
    background-position: center center !important; }
  .top-image.bottom-center {
    background-position: bottom center !important; }
  .top-image.bottom-left {
    background-position: bottom left !important; }
  .top-image.bottom-right {
    background-position: bottom right !important; }

@media print {
  header,
  footer {
    display: none; } }
