@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.category-container{
  padding: 5rem 0 8.5rem;
}

.row.category,.row.support,.row.dealers{
  margin-top: 5rem;
  [class^="col-"]{

    &:nth-child(2){
      .category-item,
      .product-support-item,
      .dealers-item,
      .eshop-item{
        @include media(md){
          @include border-radius(0 7px 0 0);
        }
        @include media(lg){
          @include border-radius(0);
        }
      }

    }
    &:nth-child(3){
      .category-item,
      .product-support-item,
      .dealers-item,
      .eshop-item{
        @include media(md){
          @include border-radius(0 0 0 7px);
        }
        @include media(lg){
          @include border-radius(0);
        }
      }
    }
    &:first-child{
      .category-item,
      .product-support-item,
      .dealers-item,
      .eshop-item{
        @include border-radius(7px 7px 0 0);
        @include media(md){
          @include border-radius(7px 0 0);
        }
        @include media(lg){
          @include border-radius(7px 0 0 7px);
        }
      }
    }
    &:last-child{
      .category-item,
      .product-support-item,
      .dealers-item,
      .eshop-item{
        @include border-radius(0 0 7px 7px);
        @include media(md){
          @include border-radius(0 0 7px);
        }
        @include media(lg){
          @include border-radius(0 7px 7px 0);
        }
      }
    }
  }
}

.category-item{
  padding: 30px 40px 60px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: $base-white;
  &-image{
    height: 140px;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include media(xxl){
      height: 150px;
    }
    img{
      margin: auto;
    }
  }
  &-name{
    text-align: center;
    font-size: 23px;
    color: $base-black;
    font-weight: 500;
    @include media(xxl){
      font-size: 25px;
    }
  }
  &:hover,
  &:focus{
    @include transform(scale(1.05));
    position: relative;
    z-index: 2;
    .category-item-name{
      color: $base-black;
    }
  }
}

.product-list{
  padding: 6rem 0;
}

.product-item{
  padding-bottom: 20px;
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  @include flex-direction(column);
  width: 100%;
  @include media(md){
    @include flex-direction(row);
  }
  color: $base-black;
  &:hover,
  &:focus{
    color: $base-black;
    .product-item-image,
    .product-item-content{
      @include box-shadow(0 0 24px rgba(0, 0, 0, 0.35));
    }
  }
  &:last-child{
    padding-bottom: 0;
  }
  &-image{
    height: 313px;
    max-width: 367px;
    width: 100%;
    background: $base-white;
    position: relative;
    z-index: 2;
    @include border-radius(7px);
    @include box-shadow(0 0 6px rgba(0, 0, 0, 0.07));
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include transition;
    @include media(xxl){
      max-width: 400px;
      height: 340px;
    }
    &.archived-product {
      img {
        filter: grayscale(100%);
      }
    }
  }
  &-content{
    width: 100%;
    max-width: 330px;
    background: $base-white;
    @include border-radius(7px);
    @include box-shadow(0 0 6px rgba(0, 0, 0, 0.07));
    @include transition;
    @include media(md){
      max-width: 395px;
    }
    @include media(lg){
      max-width: 440px;
    }
    @include media(xxl){
      max-width: 480px;
    }
    &-head{
      @include flex-block;
      @include align-items(center);
      @include justify-content(space-between);
      padding: 30px 15px 30px 50px;
      @include border-radius(7px 7px 0 0);
      font-weight: 500;
      font-size: 23px;
      background: #eeeeee;
      @include media(md){
        padding: 30px 10px 30px 65px;
      }
      @include media(lg){
        padding: 30px 25px 30px 65px;
      }
      @include media(xxl){
        font-size: 25px;
        padding: 30px  25px 30px 70px;
      }
    }
    &-body{
      font-weight: 300;
      padding: 20px 20px 0px 54px;
      @include media(md){
        padding: 20px 20px 0px 54px;
      }
      @include media(lg){
        padding: 20px 25px 0px 65px;
      }
      @include media(xxl){
        padding: 23px 25px 0px 70px;
      }
    }
    &-price{
      font-weight: 300;
      line-height: 1;
      padding: 20px 20px 35px 68px;
      @include media(md){
        padding: 20px 20px 35px 68px;
      }
      @include media(lg){
        padding: 20px 25px 25px 79px;
      }
      @include media(xxl){
        padding: 25px 25px 35px 84px;
      }
      span{
        display: block;
        padding-top: 5px;
        letter-spacing: 0.44px;
        font-size: 30px;
        font-weight: 500;
        @include media(xxl){
          font-size: 34px;
        }
      }
    }
  }
}

.product-detail{
  padding: 6.5rem 0 4.5rem;
  @include flex-block;
  @include align-items(center);
  @include flex-wrap(wrap);
  line-height: 19px;
  font-weight: 300;
  position: relative;
  @include media(xxl){
    line-height: 21px;
  }
  &-360{
    /*
    position: absolute;
    left: 3rem;
    top: 9.5rem;
    z-index: 20;
    a:before{
      font-size: 30px;
      color: $main-color;
    }*/
    &-iframe {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 6rem 0 3rem;
        width: 100%;
        position: relative;
        padding-top: 100%;
        @include media(xs) {
            padding-top: 60%;
        }
        @include media(md) {
            padding-top: 40%;
        }
        iframe {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.85);
            height: 100%;
            width: 320px;
            border: 0;
            @include media(xs) {
                transform: translate(-50%, -50%);
                width: 100%;
            }
            @include media(lg) {
                max-width: 60rem;
            }
        }
    }
  }
  &-gallery{
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    @include slick-arrows;
    @include slick-dots;
    @include media(md){
      width: 55%;
      padding-right: 30px;
    }
    @include media(lg){
      padding-right: 55px;
    }
    @include media(xxl){
      padding-right: 65px;
    }
    .slick-list{
      @include border-radius(7px);
      @include box-shadow(0 0 24px rgba(0, 0, 0, 0.06));
    }
    .slick-cloned,.Pager{
      display: none;
    }
    .gallery-item{
      background: $base-white;
      height: 350px;
      //max-width: 350px;
      @include media(md){
        height: 350px;
        //max-width: 350px;
      }
      @include media(lg){
        height: 523px;
        //max-width: 523px;
      }
      @include media(xxl){
        height: 575px;
        //max-width: 575px;
      }
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
    }
    .slick-dots{
      bottom: -45px;
      @include media(md){
        @include transform(translateX(-15px));
      }
      @include media(lg){
        @include transform(translateX(-27.5px));
      }
      @include media(xxl){
        @include transform(translateX(-32.5px));
      }
      li{
        button{
          border: 4px solid #bbbbbb;
          width: 15px;
          height: 15px;
        }
        &.slick-active{
          button{
            background: #bbbbbb;

          }
        }
      }
    }
  }
  &-description{
    width: 100%;
    @include media(md){
      width: 45%;
      padding-left: 30px;
    }
    @include media(lg){
      padding-left: 55px;
    }
    @include media(xxl){
      padding-left: 65px;
    }
    &-title{
      text-transform: uppercase;
    }
    &-perex{
      padding-bottom: 35px;
    }
    &-images{
      padding: 45px 0;
      img{
        margin-right: 20px;
      }
    }
    &-price{
      @include flex-block;
      @include align-items(center);
      &-btn{
        padding-right: 25px;
        display: inline-block;
        .btn{
          padding: 1.5rem 3.5rem 1.5rem 4rem;
        }
      }
      &-text{
        line-height: 1;
        span{
          display: block;
          padding-top: 5px;
          letter-spacing: 0.44px;
          font-size: 30px;
          font-weight: 500;
          @include media(xxl){
            font-size: 34px;
          }
        }
      }
    }
  }
}

.gray-bgr{
  background: #f6f6f6;
}

.detail-description{
  padding: 5rem 0;
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  @include flex-wrap(wrap);
  &-content{
    width: 100%;
    text-align: justify;
    font-weight: 300;
    @include media(lg){
      padding-left: 15px;
      width: 50%;
    }
    h1,h2,h3,h4{
      padding-bottom: 1.5rem;
    }
  }
  &-image{
    width: 100%;
    padding: 50px;
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include media(lg){
      padding: 15px;
      width: 50%;
    }
  }
  &.reversed{
    .detail-description-content{
      @include media(lg){
        order: 2;
      }
    }
    .detail-description-image{
      @include media(lg){
        order: 1;
      }
    }
  }
}
.detail-video{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  background-position: center!important;
  background-size: cover!important;
  background-repeat: no-repeat!important;
  height: 250px;
  @include media(md){
    height: 400px;
  }
  @include media(lg){
    height: 600px;
  }
  @include media(xxl){
    height: 660px;
  }
  a{
    font-size: 75px;
    @include transition;
    &:focus,
    &:hover{
      @include transform(scale(1.1));
    }
  }
}

.detail-hubble{
  @include flex-block;
  //@include align-items(center);
  @include justify-content(center);
  @include flex-wrap(wrap);
  padding-top: 70px;
  @include media(md){
    //padding: 0 50px;
    padding-top: 140px;
    @include justify-content(space-between);
  }
  @include media(lg){
    padding: 0 200px;
    padding-top: 85px;
  }
  @include media(xxl){
    padding-top: 90px;
  }
  &-content{
    width: 100%;
    max-width: 350px;
    @include media(md){
      width: auto;
    }
    @include media(xxl){
      max-width: 390px;
    }
    &-perex{
      padding: 25px 0 30px;
      font-weight: 300;
      @include media(xxl){
        padding: 30px 0;
      }
    }
    &-btns{
      @include flex-block;
      @include align-items(center);
      margin-bottom: 35px;
      @include media(md){
        margin-left: -10px;
      }
      @include media(xxl){
        margin-left: -15px;
      }
      a:hover,
      a:focus{
        @include box-shadow(0 0 6px rgba(0, 0, 0, 1));
      }
      .btn-app{
        overflow: hidden;
        background: #000;
        @include border-radius(1000px);
        height: 50px;
        padding: 0 20px;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        @include box-shadow(0 0 6px rgba(0, 0, 0, 0.5));
        margin-right: 10px;
        @include media(md){
          height: 60px;
        }
        @include media(xxl){
          height: 70px;
        }
        img{
          width: 150px;
          height: 150px;
        }
      }
    }
    &-url{
      color: #679ac2;
      &:focus,
      &:hover{
        color: darken(#679ac2, 20);
      }
    }
  }
}

.detail-download{
  padding: 8.5rem 0 10rem;
  .h4{
    padding-bottom: 2rem;
  }
  &-content{
    padding-left: 10px;
    &-btn{
      margin: 10px 0px 10px -14px;
      display: inline-block;
      .btn{
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
        @include media(xxl){
          font-size: 16px;
          padding-right: 25px;
          padding-left: 25px;
        }
        &:after{
          font-size: 11px;
        }
      }
    }
  }
  li{
    display: block!important;
    padding-left: 14px!important;
    padding-right: 10px!important;
  }
}

.contact-container{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  @include flex-wrap(wrap);
  padding: 4rem 0 10rem;
  @include media(md){
    @include justify-content(space-between);
  }
  .form-box{
    background: $base-white;
    width: 100%;
    padding: 50px 55px 30px;
    @include box-shadow(0 0 18px rgba(0, 0, 0, 0.04));
    @include border-radius(7px);
    @include media(md){
      padding: 40px 70px 35px;
      width: 70%;
    }
    @include media(lg){
      padding: 55px 85px 35px;
      max-width: 780px;
    }
    @include media(xxl){
      padding: 60px 85px 40px;
      max-width: 820px;
    }
    h1,h2,h3,h4{
      padding-bottom: 10px;
    }
    &-text{
      text-align: center;
      font-weight: 300;
      @include media(lg){
        text-align: left;
        max-width: 365px;
      }
      @include media(xxl){
        max-width: 380px;
      }
    }
    .form{
      width: 100%;
      padding-top: 12px;
      &-contact{
        @include media(lg){
          max-width: 255px;
        }
        @include media(xxl){
          max-width: 270px;
        }
      }
      &-btn{
        //padding-top: 8px;
        text-align: center;
        @include media(md){
          text-align: left;
        }
        &-inner{
          display: inline-block;
          position: relative;
          &:after{
            position: absolute;
            top: 50%;
            right: 3rem;
            content: "\e903";
            color: $base-white;
            padding-left: 8px;
            font-size: 16px;
            @include transform(translateY(-50%));
          }
        }
        .btn{
          padding-right: 8rem;
          @include media(md){
            padding-right: 5rem;
          }
        }
      }
    }
  }
  .text-box{
    @include flex-block;
    @include flex-wrap(wrap);
    @include justify-content(space-between);
    padding-top: 45px;
    @include media(md){
      padding-top: 0px;
      padding-left: 10px;
      @include flex-direction(column);
    }
    @include media(xxl){
      padding-right: 50px;
    }
    &-title{
      width: 100%;
      padding-bottom: 20px;
      text-align: center;
      @include media(md){
        text-align: left;
      }
    }
    &-column{
      padding-right: 10px;
    }
  }
}

.intro{
  max-width: 440px;
  margin: auto;
  @include media(xxl){
    max-width: 485px;
  }
}

.row.support{
  padding: 0rem 0 10rem;
}

.product-support-item{
  padding: 30px 40px 60px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: $base-white;
  @include transition;
  &-image{
    height: 140px;
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include media(xxl){
      height: 150px;
    }
    img{
      margin: auto;
    }
  }
  &-name{
    text-align: center;
    font-size: 23px;
    color: $base-black;
    font-weight: 500;
    @include media(xxl){
      font-size: 25px;
    }
  }
  &-docs{
    a{
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      font-weight: 500;
      padding-top: 15px;
      &:after{
        content: "\e901";
        color: $main-color;
        padding-left: 5px;
      }
    }
  }
}

.stores-btns-row{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  margin: auto;
  max-width: 310px;
  background: $base-white;
  border: 1px solid #e6e6e6;
  @include box-shadow(0 0 9px rgba(0, 0, 0, 0.03));
  @include border-radius(7px);
  height: 60px;
  font-size: 15px;
  @include media(xxl){
    font-size: 16px;
    max-width: 340px;
    height: 65px;
  }
  span,a{
    width: 50%;
    height: 100%;
    color: $base-black;
    font-weight: 300;
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
  }
  span{
    background: #e6e6e6;
  }
  a:hover,a:focus{
    color: $base-black;
    background: #e6e6e6;
  }
}

.region-group{
  padding: 3rem 0;
  h3{
    padding-bottom: 2rem;
    color: #126caa;
  }
}

.dealers-item{
  padding: 95px 0px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: $base-white;
  text-align: center;
  @include media(xxl){
    padding: 100px 20px;
  }
  @include transition;
  &-name{
    text-align: center;
    font-size: 23px;
    color: $base-black;
    font-weight: 500;
    @include media(xxl){
      font-size: 25px;
    }
  }
  &-city{
    padding: 5px 0;
  }
  a{
    color: #416ba4;
    &:hover,
    &:focus{
      text-decoration: underline;
    }
  }
}

.eshop-item{
  padding: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
  width: 100%;
  background: $base-white;
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  @include transition;
  height: 260px;
  @include media(xxl){
    height: 290px;;
  }
}

.eshops{
  a:hover,
  a:focus{
    .eshop-item{
      @include transform(scale(1.05));
    }
  }
}

.lang-switch{
  ul{
    height: 20px;
    width: 30px;
    position: relative;
    li{
      height: 20px;
      width: 30px;
      position: absolute;
      left: 0;
      top: 0;
      .cs-cz,
      .sk-sk{
        font-size: 0;
        display: block;
        width: 30px;
        height: 20px;
        background-image: url('../img/header_language-CZ.svg');
        background-position: center!important;
        background-size: cover!important;
        background-repeat: no-repeat!important;
        @include box-shadow(0 0 6px rgba(0, 0, 0, 0.07));
        position: relative;
        z-index: 2;
      }
      .sk-sk{
        background-image: url('../img/header_language-SK.svg');
      }
      .active{
        display: none;
      }
    }
  }
}

.archived-label {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $base-white !important;
  border-radius: 50%;
  z-index: 99;
  text-shadow: 0 0 3px $base-black;
}

.archived-layer {
    user-select: none;
    pointer-events: none;
    filter: grayscale(100%);
}
