
@import "../../../../sass/_mixins/pux/breakpoints.scss";
@import "../../../../sass/_mixins/pux/css-rules.scss";
@import "../../../../sass/_mixins/pux/pux-content.scss";
@import "../../../../sass/_mixins/pux/scale.scss";
@import "../../../../sass/_variables.scss";


$top-image-header-height: 9rem;
$top-image-content-center: true;
$top-image-color: #282828;
$top-image-max-width: 50rem;
$top-image-full-window: false;
$top-image-height: 35rem;

.top-image{
  width: 100%;
  background-repeat: no-repeat !important;
  @include background-size(cover);
  background-position: center center !important;
  @include flex-block;
  @include flex-direction(row);
  position: relative;
  &:before{
    content: "";
    background: $base-white;
    position: absolute;
    width: 100%;
    height: 100%;
    @include opacity(0.5);
    top: 0;
    left: 0;
  }
  &-inner{
    width:100%;
    padding:5rem 0;
    @if $top-image-full-window{
       @include calc("min-height", "100vh - "+ $top-image-header-height);
    } @else {
       min-height: 350px;
    }
    @include flex-block;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
    position: relative;
    z-index: 2;
    &-content{
      max-width: $top-image-max-width;
      margin: auto;
      @if $top-image-content-center{
        text-align: center;
      }
      &-text{
        color: $top-image-color;
        h1,
        h2{
          color: $top-image-color;
          margin:0;
          padding:0 0 1.5rem 0;
        }
      }
      &-btn{
        padding: 20px 0 0 0;
      }
    }
    .pux-container{
      width:100%;
    }
  }
  &.top-center{
    background-position: top center !important;
  }
  &.top-left{
    background-position: top left !important;
  }
  &.top-right{
    background-position: top right !important;
  }
  &.center-center{
    background-position: center center !important;
  }
  &.bottom-center{
    background-position: bottom center !important;
  }
  &.bottom-left{
    background-position: bottom left !important;
  }
  &.bottom-right{
    background-position: bottom right !important;
  }
}
