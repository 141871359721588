
@import "../../../../sass/_mixins/pux/breakpoints.scss";
@import "../../../../sass/_mixins/pux/css-rules.scss";
@import "../../../../sass/_mixins/pux/pux-content.scss";
@import "../../../../sass/_mixins/pux/scale.scss";
@import "../../../../sass/_variables.scss";

$slider-header-height: 9rem;
$slider-content-center: false;
$slider-color: #fff;
$slider-max-width: 500px;
$slider-full-window: false;
$slider-height: 570px;
$slider-arrow: false;
$slider-dot: true;

.slider-init{
 //margin-bottom: -25px;
 position: relative;
 box-shadow: 0 0 18px rgba(0, 0, 0, 0.23);
  .main-slider{
    width: 100%;
    background-repeat: no-repeat !important;
    @include background-size(cover);
    @include flex-block;
    @include flex-direction(row);
    &-inner{
      width:100%;
      padding:6rem 0 60px 0;
      @if $slider-full-window{
        @include calc("min-height", "100vh - "+ $slider-header-height);
      } @else {
         min-height: $slider-height;
         height: auto!important;
         height: $slider-height;
      }
      @include flex-block;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      &-content{
        max-width: $slider-max-width;
        @include media(lg){
          width: 50%;
        }
        @if $slider-content-center{
          text-align: center;
        }
        &-text{
          color: $slider-color;
          h1,
          h2{
            color: $slider-color;
            padding-bottom: 1rem;
          }
        }
        &-btn{
          padding: 3rem 0 0 0;
          display: inline-block;
        }
      }
      .pux-container{
        width:100%;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        @include flex-wrap(wrap);
        @include media(lg){
          @include justify-content(space-between);
        }
      }
      &-image{
        width: 100%;
        text-align: center;
        @include media(lg){
          width: 50%;
        }
      }
    }
  }
  @if $slider-arrow{
    @include slick-arrows;
  }
  @if $slider-dot{
    @include slick-dots;
  }
}
