@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8f2kke');
  src:  url('fonts/icomoon.eot?8f2kke#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?8f2kke') format('woff2'),
    url('fonts/icomoon.ttf?8f2kke') format('truetype'),
    url('fonts/icomoon.woff?8f2kke') format('woff'),
    url('fonts/icomoon.svg?8f2kke#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-down:before {
  content: "\e900";
  color: #fff;
}
.icon-download:before {
  content: "\e901";
}
.icon-play:before {
  content: "\e902";
  color: #fff;
}
.icon-right:before {
  content: "\e903";
  color: #fff;
}
.icon-search:before {
  content: "\e904";
  color: #bbb;
}
.icon-slider-left:before {
  content: "\e905";
}
.icon-slider-right:before {
  content: "\e906";
}
.icon-360:before {
  content: "\e907";
  color: #f8b620;
}
