
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/pux-content.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
  margin-left: auto;
  margin-right: auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  max-width: $container-max-width;
  &:after{
      display: block;
      content: "";
      clear: both;
  }
  @include media(md){
      padding-left: $container-padding-left-md;
      padding-right: $container-padding-right-md;
  }
  @include media(lg){
    padding-left: 15.5rem;
    padding-right: 15.5rem;
  }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    [class^="col-"]{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include flex-block;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

header{
  width: 100%;
  background-color: $base-white;
  position: fixed;
  @include media($menu-break){
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.23);
  }
  z-index: 77;
  &.scrolled{
    .header{
      @include media($menu-break){
        padding: 2rem 0rem;
        height: 6rem;
      }
      &-logo{
        @include media($menu-break){
          @include transform(translateX(-10%) scale(0.8));
        }
      }
    }
  }
  .pux-container{
    @media (max-width: 991px){
      padding: 0;
    }
  }
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.18);
    padding: 1rem 25px;
    height: 9rem;
    position: relative;
    @include transition;
    @include media(md){
      padding: 1rem 40px;
    }
    @include media($menu-break){
      padding: 1rem 0;
      box-shadow: none;
      z-index: 200;
    }
    &-logo{
      @include transition;
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          padding: 0;
          width: 15rem;
          height: 6.4rem !important;
        }
      }
    }
    &-box{
      @include align-items(center);
      position: relative;
      @include flex-block;
      &.open{
        .header-searchbox{
          display: block;
        }
      }
      .lang-switch,
      .header-search{
        padding-right: 20px;
      }
      .lang-switch{
        order: 1;
        @include media(lg){
          order: 2;
          @include flex-block;
          @include align-items(center);
        }
        &:before{
          @include media(lg){
            content: "";
            height: 38px;
            width: 1px;
            background: #bbbbbb;
            margin-right: 20px;
            display: block;
          }
        }
      }
      .header-search{
        order: 2;
        @include media(lg){
          order: 1;
        }
        height: 22px;
        @include media(xxl){
          height: 25px;
        }
        .search-switch{
          height: 22px;
          @include media(xxl){
            height: 25px;
          }
          font-size: 18px;
          @include media(xxl){
            font-size: 20.5px;
          }
        }
      }
      .header-hamburger{
        order: 3;
      }
      .header-searchbox{
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        width: 280px;
        padding: 0 5px 0 15px;
        background: $base-white;
        @include border-radius(7px);
        filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.07));
        @include transform(translate(0,10px));
        @media (min-width:375px){
          width: 320px;
        }
        @include media(sm){
          width: 420px;
        }
        @include media(md){
          @include transform(translate(0,18px));
        }
        @include media(lg){
          @include transform(translate(-100%,18px));
          right: none;
          left: 90px;
        }
        @include media(xxl){
          width: 460px;
          max-width: 460px;
          //@include transform(translate(-100%,18px));
        }
        &:before{
            content: "";
            display: block;
            position: absolute;
            top: 1px;
            right: 0;
            @include triangle-top($left:10px, $right:10px, $bottom:10px, $color:$base-white);
            @include transform(translate(-40px,-100%));
            @include media(lg){
              @include transform(translate(-70px,-100%));
            }
        }
        &:after{
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 50%;
            @include transform(translate(-50%,0));
            background: $main-color;
            height: 2px;
            width: 270px;
            @media (min-width:375px){
              width: 310px;
            }
            @include media(sm){
              width: 410px;
            }
            @include media(xxl){
              width: 450px;
            }
        }
        //display: none;
        .searchBox{
          @include transition;
          @include opacity(1);
          @include flex-block;
          @include align-items(center);
          .btn{
            padding: 0 30px 0 20px;
            height: 50px;
            background: $main-color;
            color: $base-white;
          }
          input{
            margin-right: 15px;
          }
          label{
            display: none;
          }
          &:after{
            position: absolute;
            bottom: 50%;
            right: 30px;
            content: "\e903";
            color: $base-white;
            //padding-left: 8px;
            font-size: 16px;
            @include transform(translateY(50%));
          }
        }
      }
      ul{
        margin: 0;
        list-style: none;
        padding: 0;
      }
    }
    &-menu{
      @include transition;
      @include transform(translateX(1000px));
      position: absolute;
      top:100%;
      z-index: -1;
      right:0;
      background: $base-white;
      min-width: 280px;
      padding: 20px 0 55px 90px;
      @include calc("max-height", "100vh - 5rem");
      @include border-radius(0 0 0 7px);
      @include media($menu-break){
        @include flex-block;
        position: static;
        background: none;
        width:auto;
        min-width: none;
        @include transform(translateX(0px));
        padding: 0px;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 100%;
        right: 0;
        //@include transform(translate(-50%,0));
        background: $main-color;
        height: 2px;
        width: 276px;
        @include media($menu-break){
          display: none;
        }
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
        }
        li{
          display: block;
          position: relative;
          @include media($menu-break){
            margin:0 1rem;
            @include flex-block;
            @include align-items(center);
          }
          a,span{
            display:block;
            padding: 15px 0;
            color:$base-black;
            font-size: 14px;
            @include media(lg){
              padding: 0 25px;
            }
            @include media(xl){
              font-size: 15px;
              padding: 0 30px;
            }
            text-transform: uppercase;
            color: #7f8080;
            &:focus,
            &:hover{
              color:$main-color;
              text-decoration: none;
            }
          }
          &.Highlighted{
            a{
              color:$main-color;
            }
          }
          ul{
            background: $base-white;
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width: 180px;
              visibility: hidden;
              @include opacity(0);
              display: block;
              top: 40px;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
              padding: 10px 0;
              left: 50%;
              @include transform(translateX(-50%));
              @include border-radius(7px);
              filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.07));
            }
            &:before{
              @include media($menu-break){
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 50%;
                @include transform(translate(-50%,-100%));
                @include triangle-top($left:10px, $right:10px, $bottom:10px, $color:$base-white);
                //box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
              }
            }
            &:after{
              @include media($menu-break){
                content: "";
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                @include transform(translate(-50%,0));
                background: $main-color;
                height: 2px;
                width: 168px;
              }
            }
            li{
              @include media(lg){
                padding: 0 15px 0 30px;
              }
              a{
                text-transform: none;
                position: relative;
                font-weight: 300;
                display: inline-block;
                color: $base-black!important;
                @include media(lg){
                  padding: 15px 0;
                }
                &:after{
                  content: "";
                  width: 0%;
                  height: 1px;
                  position: absolute;
                  bottom: 13px;
                  left: 0;
                  background: $base-black;
                  @include transition;
                }
                &:focus,
                &:hover{
                  &:after{
                    width: 100%;
                  }
                }
              }
            }
          }
          &.hovered,
          &:hover{
            & > a{
              color:$main-color;
            }
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include transform(translateY(0%) rotate(180deg));
              }
            }
            ul{
              display:block;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:22.5px;
        right:15rem;
        color: $base-black;
        @include transform(translateY(-50%));
        width: 6rem;
        height: 6rem;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        @include media(sm){
          right: 12rem;
        }
        @include media(md){
          right:9rem;
        }
        @include media($menu-break){
          display: none;
        }
        &:after{
          content:"\e900";
          @include transition;
          font-size: 6px;
          position: absolute;
        }
      }
    }
    &-hamburger{
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width: $hamburger-width;
          position: absolute;
          left:0.5rem;
          height:3px;
          background: #a5a5a5;
          margin-top:-1.5px;
          @include border-radius(2px);
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width;
            left:0;
            height:3px;
            background: #a5a5a5;
            @include border-radius(2px);
            position: absolute;
            @include transition;
          }
          &:before{
            top:-8px;
          }
          &:after{
            bottom:-8px;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            @include transform(translateX(100px));
            &:before{
              top:0;
              //@include rotate(45deg);
              @include transform(translateX(-100px) rotate(45deg));
            }
            &:after{
              top:0;
              //@include rotate(-45deg);
              @include transform(translateX(-100px) rotate(-45deg));
            }
          }
        }
      }
      .header-menu{
        @include transform(translateX(0px));
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  background: #282828;
  padding: 20px 0;
  @include media(lg){
    padding: 40px 0;
  }
  .footer{
    @include flex-block;
    @include justify-content(space-between);
    @include flex-wrap(wrap);
    color: $base-white;
    font-size: 15px;
    line-height: 25px;
    @include media(xl){
      font-size: 16px;
      line-height: 26px;
    }
    a{
      color: $base-white;
      &:hover,
      &:focus{
        color: $main-color;
      }
    }
    div{
      padding: 10px 0;
      width: 100%;
      text-align: center;
      @include media(md){
        width: 25%;
        text-align: left;
      }
      @include media(lg){
        padding: 0;
        width: auto;
        position: relative;
        z-index: 3;
      }
      big{
        font-size: 18px;
        padding-bottom: 5px;
        line-height: 28px;
        @include media(xl){
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .footer-logo{
      @include media(md){
        width: 100%;
      }
      @include media(lg){
        width: auto;
        padding-right: 10rem;
      }
      @include media(xl){
        padding-right: 12rem;
      }
      img{
        width: 262px;
        height: 60px;
      }
    }
    .footer-column{
      padding-right: 2rem;
      &:last-child{
        width: 100%;
        position: relative;
        font-size: 13px;
        padding-top: 30px;
        @include media(md){
          padding-top: 10px;
        }
        @include media(lg){
          font-size: 14px;
          padding-top: 0;
          position: relative;
          top: -26px;
          z-index: 1;
        }
      }
    }
  }
}

.is-sm{
    display:none;
    @include media(sm){
        display:block;
    }
}

.is-md{
    display:none;
    @include media(md){
        display:block;
    }
}

.is-lg{
    display:none;
    @include media(lg){
        display:block;
    }
}

.is-xl{
    display:none;
    @include media(xl){
        display:block;
    }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}

//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}
